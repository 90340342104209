import Header from "../../components/header/header";
// import BackgroundGrid from "../landing/backgroundgrid";
import HeroMarketplace from "./HeroMarketplace";

const MarketPlace = () => {
  return (
    <div>
      {/* <BackgroundGrid /> */}
      <Header />
      <HeroMarketplace />
    </div>
  );
};

export default MarketPlace;
