import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Waitlist from "../../components/forms/waitlist/waitlist";
import BackgroundGrid from "./backgroundgrid";
import PwcLogo from "./images/pwc.png";
import KPMGLogo from "./images/kpmg.png";
import MobilLogo from "./images/mobil.png";
import ShellLogo from "./images/shell.png";
import FidelityLogo from "./images/fidelity.png";
// import Test from "./images/test.svg";
import Banner from "./images/banner.png";
import LandImg from "./images/land.svg";
// import LNFT from "./images/LNFT.png";
// import Cities from "./images/cities.svg";
import GridBlocks from "./images/grid-block.svg";
import Property from "./images/property.svg";
import "./landing.scss";

function Landing() {
  return (
    <main className="bdhe-landing">
      <BackgroundGrid />
      <Header />

      <section className="bdhe-landing__banner">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-md-6">
                <div className="bdhe-landing__banner__manifest">
                  <h2 className="animate__animated animate__fadeInUp animate__delay-100ms">
                    Digitising Real Estate in Africa <span>.</span>{" "}
                  </h2>
                  <p className="animate__animated animate__fadeInUp animate__delay-990ms">
                    BHDE is offering customers access to highly vetted
                    investment opportunities to fractionalized real estate in
                    emerging markets through on the blockchain with an
                    end-to-end digital real estate investment ecosystem.{" "}
                  </p>
                  {/* <a href="" className="btn">Join Waitlist</a> */}
                  <div className="bdhe-landing__banner__buttons">
                    <a
                      href="https://docs.google.com/document/d/1QhRFrWmn_9-x9gw1b2ZDr9tlFs_6mxUxk0otmwkMJdA/preview"
                      // href="/#waitlist"
                      target="_blank"
                      className="btn"
                      rel="noopener noreferrer"
                    >
                      LitePaper
                    </a>
                    <a
                      // href="https://www.netlify.com/products/forms/"
                      href="/#waitlist"
                      // target="_blank"
                      className="btn waitlist"
                    >
                      Join Waitlist
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bdhe-landing__banner__illustration d-none d-md-flex">
                  <img src={Banner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bdhe-landing__background-grid__overlay"></div>
      </section>

      <section className="bdhe-landing__partners">
        <div className="container">
          {/* <div className="bdhe-landing__partners__heading">
              <h2>Here is a list of Partners and Supporters</h2>
          </div> */}
          <div className="bdhe-landing__partners__list">
            <div className="bdhe-landing__partners__list__item">
              <img src={PwcLogo} alt="" />
            </div>
            <div className="bdhe-landing__partners__list__item">
              <img src={KPMGLogo} alt="" />
            </div>
            <div className="bdhe-landing__partners__list__item">
              <img src={MobilLogo} alt="" />
            </div>
            <div className="bdhe-landing__partners__list__item">
              <img src={ShellLogo} alt="" />
            </div>
            <div className="bdhe-landing__partners__list__item">
              <img src={FidelityLogo} alt="" />
            </div>
            <div className="bdhe-landing__partners__list__item">
              <img src={MobilLogo} alt="" />
            </div>
            
          </div>
        </div>
      </section>

      <section id="lnft"
        className="bdhe-landing__features"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="container">
          <div className="">
            <div className="row" style={{gap:'50px'}}>
              <div className="col-12 col-lg">
                <div className="col d-flex">
                <div className="bdhe-landing__features__heading">
                  <h2><span>BHDE</span> is building a <span>next generation investment</span> platform</h2>
                  {/* <div className="bdhe-landing__features__heading__glow"></div> */}
                  <p>
                    
                    that will change property investment and development around
                    Africa by allowing investors to leverage blockchain
                    technology and invest in tangible, long-term returns, while
                    also giving investors an opportunity for fractional
                    ownership.
                  </p>
                </div>
              </div>
              <div className="bdhe-landing__features__item">
                  <h2 className="bdhe-landing__features__item__title">
                    Landed NFT <span>(LNFT)</span>
                  </h2>
                  <p className="bdhe-landing__features__item__text">
                    BHDE Offers the world's first LNFT, fractionalized
                    geospatial point as an NFT representing a real world
                    physical space. Lack of Verified land ownership certificate is a key
                      barrier to international investment and development in the
                      African real estate market.
                  </p>
            
                  <div className="bdhe-landing__features__item__image">
                    <img src={GridBlocks} alt="" style={{marginTop:'2rem'}}/>
                  </div>
                </div>
                <div className="col d-flex">
                <div className="bdhe-landing__features__heading">
                  <h2 style={{fontSize:'28px', margin: '3.6rem 0 1.2rem', lineHeight: '44px'}}> <span>BHDE</span> main goal is to <span>facilitate access</span> to affordable, reliable, sustainable and <span>modern assets</span></h2>
                  <p>
                    
                    for all from real estate, energy to farming and local marketplaces. Access to real estate, food, marketplaces and energy are important pillars for the wellbeing of the people as well as economic development and poverty alleviation.
                  </p>
                </div>
              </div>
              </div>
              <div className="col-12 col-lg" style={{display:'flex', flexDirection: 'column', gap:'32px'}}>
                <div className="bdhe-landing__features__item">
                  <div className="bdhe-landing__features__item__image">
                    <img src={LandImg} alt="" />
                  </div>
                  <h2 className="bdhe-landing__features__item__title">
                    Asset <span>Tokenization</span>
                  </h2>
                  <p className="bdhe-landing__features__item__text">
                    Real estate assets are difficult to physically divide or
                    transfer, but ownership rights can be turned into digital
                    tokens. Tokenization makes it easier for asset or fund owners to
                      obtain funds, and it allows investors unparalleled access
                      to private real estate assets, transparency, and
                      liquidity.
                  </p>
                  <a
                      href="/#waitlist"
                      className="btn waitlist"
                    >
                      Join Waitlist
                    </a>
                </div>
                <div className="bdhe-landing__features__item">
                  <h2 className="bdhe-landing__features__item__title">
                    Property Token <span>(BHPT)</span>
                  </h2>
                  <p className="bdhe-landing__features__item__text">
                    The property tokens are a set of tokens minted to represent a share in a property. Ownership of each property is distributed across a finite number of representative tokens. Based on token share, owners can collect revenue from rent, and vote on property decisions.
                  </p>
                  <div className="bdhe-landing__features__item__image">
                    <img src={Property} alt="" style={{marginTop:'2rem'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bdhe-landing__about">
        <div
          className="bdhe-landing__about-wrap"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="container">
            <div className="">
              <div className="row align-items-center g-5">
                <div className="col-md-6">
                  <div className="bdhe-landing__about__item">
                    <h2 className="bdhe-landing__about__item__title">
                      Landed <span>NFT</span> (LNFT)
                    </h2>
                    <p className="bdhe-landing__about__item__text">
                      Lack of Verified land ownership certificate is a key
                      barrier to international investment and development in the
                      African real estate market.
                    </p>
                    <p className="bdhe-landing__about__item__text">
                      The complexity of traditional shared land ownership forms,
                      which are difficult to establish in a statutory tenure, is
                      typically the driving force behind this problem.
                    </p>
                    <p className="bdhe-landing__about__item__text">
                      Nonetheless, there is widespread agreement that having
                      access to current geographic information on the property
                      is a critical precondition for the establishment of a
                      national land tenure system
                   
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bdhe-landing__about__illustration">
                    <img src={LNFT} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bdhe-landing__about-wrap"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="container">
            <div className="">
              <div className="row align-items-center g-5">
                <div className="col-md-6">
                  <div className="bdhe-landing__about__illustration left">
                    <img src={GridBlocks} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bdhe-landing__about__item">
                    <h2 className="bdhe-landing__about__item__title">
                      Asset <span> Tokenization</span>
                    </h2>
                    <p className="bdhe-landing__about__item__text">
                      Real estate tokenization is a new concept that brings real
                      estate investing and blockchain technology together.
                    </p>
                    <p className="bdhe-landing__about__item__text">
                      Tokenization makes it easier for asset or fund owners to
                      obtain funds, and it allows investors unparalleled access
                      to private real estate assets, transparency, and
                      liquidity.
                    </p>
                    <p className="bdhe-landing__about__item__text">
                      Real estate assets are difficult to physically divide or
                      transfer, but ownership rights can be turned into digital
                      tokens backed by the assets themselves using the
                      tokenisation on the blockchain.
                   
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


       

        <div
          className="bdhe-landing__about-wrap"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="container">
            <div className="">
              <div className="row align-items-center g-5">
                <div className="col-md-6">
                  <div className="bdhe-landing__about__item">
                   
                    <h2 className="bdhe-landing__about__item__title">
                      <span>Property</span> Token (BHPT)
                    </h2>
                    <p className="bdhe-landing__about__item__text">
                      The property tokens are a set of tokens minted to
                      represent a share in a property. Ownership of each
                      property is distributed across a finite number of
                      representative tokens.
                    </p>
                    <p className="bdhe-landing__about__item__text">
                      Based on token share, owners can collect revenue from
                      rent, and vote on property decisions.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bdhe-landing__about__illustration">
                    <img src={LandImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <div className="bdhe-landing__about-wrap__divider left" id= "roadmap">
          <div className="container-fluid">
            <div className="bdhe-landing__about-wrap__divider__line"></div>
          </div>
        </div>

      <section
        className="bdhe-landing__roadmap"
        id="roadmap"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="bdhe-landing__roadmap__heading text-center">
                <h2>Roadmap</h2>
                <div className="bdhe-landing__features__heading__glow"></div>
                <p>
                  The main goal of the BHDE is to facilitate access to
                  affordable, reliable, sustainable and modern assets for all
                  from real estate, energy to farming and local marketplaces.
                </p>
              </div>
            </div>
          </div>

          <div className="row" >
            <div className="col-12">
              <div className="bdhe-landing__roadmap__timeline">
                <div className="bdhe-landing__roadmap__timeline__item">
                  <div className="bdhe-landing__roadmap__timeline__item__date">
                    <p>2021 Q4</p>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Kick Off: Ecosystem Launch</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-archive" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Prototype Real Estate Marketplace</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bdhe-landing__roadmap__timeline__item">
                  <div className="bdhe-landing__roadmap__timeline__item__date">
                    <p>2022 Q1</p>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Landed NFT Launch </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Landed NFT Plot Offering 1</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Property Token Offering</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Africa Fintech Incubation Hackathon</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>BHDE Real Estate Digital Backed Loans</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Digital Wallet Prototype Launch</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Use of Funds</h6>

                          <p>
                            Plot Development for the purchased Landed NFT (LNFT)
                          </p>
                          <p>Ecosystem Expansion</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bdhe-landing__roadmap__timeline__item">
                  <div className="bdhe-landing__roadmap__timeline__item__date">
                    <p>2022 Q2</p>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Real Estate Market Place Full Launch</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Marketplace NFT Launch</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>
                            BHDE Land Mapping and Registry Platform Launch
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Launch: Sustainable City IPO 1</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Hotel Rooms Tokenisation Offering</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Landed NFT Plot Offering 2</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Use of Funds</h6>
                          <p>
                            Plot Development for the purchased Landed NFT (LNFT)
                          </p>
                          <p>Build Sustainable City no 2</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bdhe-landing__roadmap__timeline__item">
                  <div className="bdhe-landing__roadmap__timeline__item__date">
                    <p>2022 Q3</p>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Africa Fintech Incubation Grant Round 2</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>BHDE Integrated Farming Platform Launch</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>BHDE Discount Utility Token Campaign Launch</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-auto">
                      <div className="bdhe-landing__roadmap__timeline__item__content d-flex">
                        {/* <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div> */}
                        <div className="bdhe-landing__roadmap__timeline__item__content__text">
                          <h6>Launch: Sustainable City ITO</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bdhe-landing__waitlist" id="waitlist">
        <div className="container">
         <div className="">
            <div className="row justify-content-center text-center">
              <div className="col">
                <div className="bdhe-landing__waitlist__header">
                  <h2>Join the waitlist for early access and rewards</h2>
                </div>
                  <Waitlist />
              </div>
            </div>
         </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default Landing;
