import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/BH-white.svg";
import "./footer.scss";

function Footer() {
  return (
    <footer className="bdhe-global-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="">
              <div className="bdhe-global-footer__brand">
                <div className="bdhe-global-footer__brand__logo">
                  <img alt="BHDE Logo" className="logo" src={Logo} />
                  {/* <h3> Brains and Hammers Digital Ecosystem</h3> */}
                </div>
                <p>Digitising Real Estate in Africa</p>
                <p className="copyright">Copyright {new Date().getFullYear()} 300capital.io</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="bdhe-global-footer__links">
              <h2>Socials</h2>
            <ul>
              <li>
                <a target="_blank" href="https://discord.gg/YUa5Hzy9rF"
                    rel="noopener noreferrer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="bhde_io"><i className="fab fa-discord"></i>Discord</a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/BrainsandHammersDigitalEcosystem"
                    rel="noopener noreferrer"><i className="fab fa-telegram" data-bs-toggle="tooltip" data-bs-placement="bottom" title="bhde_io"></i>Telegram</a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/bhde_io?s=21"
                    rel="noopener noreferrer"><i className="fab fa-twitter"></i>Twitter</a>
              </li>
              <li>
                <a target="_blank" href="https://medium.com/brains-and-hammers-digital-ecosystem"
                    rel="noopener noreferrer"><i className="fab fa-medium"></i>Medium</a>
              </li>
              {/* <li>
                <Link to="/"><i className="fab fa-instagram" aria-hidden="true"></i> Instagram</Link>
              </li> */}
            </ul>
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="bdhe-global-footer__links">
              <h2>Apps</h2>
            <ul>
              <li>
                <Link to="/#about">LNFT</Link>
              </li>
              <li>
                <Link to="/#about">Marketplace</Link>
              </li>
              {/* <li>
                <Link to="/">Swap</Link>
              </li>
              <li>
                <Link to="/">Pool</Link>
              </li>
              <li>
                <Link to="/">Stake</Link>
              </li>
              <li>
                <Link to="/">Bridge</Link>
              </li> */}
            </ul>

            </div>
          </div>
          {/* <div className="col-12 col-md-2">
            <div className="bdhe-global-footer__links">
              <h2>Exchanges</h2>
            <ul>
              <li>
                <Link to="/">Uniswap</Link>
              </li>
              <li>
                <Link to="/">Gate.io</Link>
              </li>
            </ul>

            </div>
          </div> */}
          {/* <div className="col-12 col-md-2">
            <div className="bdhe-global-footer__links">
              <h2>Token</h2>
            <ul>
              <li>
                <Link to="/">CoinMarketCap</Link>
              </li>
              <li>
                <Link to="/">Coingecko</Link>
              </li>
            </ul>

            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
