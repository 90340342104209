import React from "react";
import "./coming.scss";
import Logo from "./images/BH-alt.svg";

function Coming() {
  return (
    <main className="bdhe-coming">
      <div className="bdhe-coming__content">
      <div className="container">
         <div className="row">
         <div className="col">
            <div className="bdhe-coming__manifest">
              <img src={Logo} alt="" />
          <h1 className="bdhe-coming__manifest__title">Coming Soon</h1>
          <p> BHDE is offering customers access to highly vetted investment opportunities to 
fractionalized real estate in emerging markets through on the blockchain with an end-to-end digital real estate investment ecosystem. </p>
        </div>
         </div>
       </div>
      </div>
      </div>
    </main>
  );
}

export default Coming;
