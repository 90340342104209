import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  withRouter,
} from "react-router-dom";
import Coming from "./pages/coming/coming";
import Landing from "./pages/landing/landing";
import MarketPlace from "./pages/marketplace/marketplace";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Route path="/Coming" component={Coming} exact />
        <Route path="/marketplace" component={MarketPlace} exact />
        <Route path="/" component={Landing} exact />
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
