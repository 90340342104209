import React from "react";

function BackgroundGrid() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1362"
      height="1362"
      fill="none"
      viewBox="0 0 1362 1362"
      className="bdhe-landing__background-grid"
    >
      <path
        stroke="#8767F1"
        strokeOpacity="0.32"
        d="M1 1v1360M81 1v1360M161 1v1360M241 1v1360M321 1v1360"
      ></path>
      <path stroke="#8767F1" d="M401 1v1360" className="stroke-one"></path>
      <path
        stroke="#8767F1"
        strokeOpacity="0.32"
        d="M481 1v1360M561 1v1360M641 1v1360M721 1v1360M801 1v1360M881 1v1360"
      ></path>
      <path stroke="#8767F1" d="M961 1v1360" className="stroke-two"></path>
      <path
        stroke="#8767F1"
        strokeOpacity="0.32"
        d="M1041 1v1360M1121 1v1360M1201 1v1360M1281 1v1360M1361 1v1360M1 1h1360M1 81h1360M1 161h1360"
      ></path>
      <path stroke="#8767F1" d="M1 241h1360" className="stroke-three"></path>
      <path
        stroke="#8767F1"
        strokeOpacity="0.32"
        d="M1 321h1360M1 401h1360M1 481h1360M1 561h1360M1 641h1360M1 721h1360M1 801h1360M1 881h1360M1 961h1360"
      ></path>
      <path stroke="#8767F1" d="M1 1041h1360" className="stroke-four"></path>
      <path
        stroke="#8767F1"
        strokeOpacity="0.32"
        d="M1 1121h1360M1 1201h1360M1 1281h1360M1 1361h1360"
      ></path>
    </svg>
  );
}

export default BackgroundGrid;