import React from "react";
import "./header.scss";
import Logo from "../images/BH-alt.svg";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="bdhe-global-header">
      <div className="container">
        <div className="">
          <nav className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 bdhe-global-header__navigation">
            <a
              href="/"
              className="d-flex align-items-center col-5 col-md-3 mb-2 mb-md-0 text-dark text-decoration-none bdhe-global-header__navigation__logo"
            >
              <img src={Logo} alt="" />
            </a>

            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 bdhe-global-header__navigation__links d-none d-md-flex">
              <li>
                <a href="/#lnft" className="nav-link px-2">
                  Landed NFT
                </a>
              </li>
              <li>
                <Link to="/" className="nav-link px-2">
                  Marketplace (soon)
                </Link>
              </li>
              <li>
                <a href="/#roadmap" className="nav-link px-2">
                  Roadmap
                </a>
              </li>
              {/* <li>
                <a href="/" className="nav-link px-2">
                  FAQs
                </a>
              </li> */}
            </ul>

            <div className="col-7 col-md-3 text-end bdhe-global-header__navigation__socials d-flex justify-content-end">
              <ul className="nav">
                <li
                  className="nav-item"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="bhde_io"
                >
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://twitter.com/bhde_io?s=21"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://discord.gg/YUa5Hzy9rF"
                    rel="noopener noreferrer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="bhde_io"
                  >
                    <i className="fab fa-discord"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://medium.com/brains-and-hammers-digital-ecosystem"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-medium"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://t.me/BrainsandHammersDigitalEcosystem"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fab fa-telegram"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="bhde_io"
                    ></i>
                  </a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" target="_blank" href="https://twitter.com/bhde_io?s=21"
                    rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
