import React, { useEffect } from "react";
import { ASSET_URL, CHOSEN_THEME } from "../../constants";
import { iframeResizer } from "iframe-resizer";

const embeddedUrl = `${ASSET_URL}?embed=${CHOSEN_THEME}`;

function HeroMarketplace() {
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    iframeResizer(
      {
        log: false,
        autoResize: false,
        minHeight: "89vh",
        heightCalculationMethod: "max",
      },
      "#opensea-iframe"
    );
  }, []);

  return (
    <div>
      {/* <div className="hero_marketplace" style={{ position: "relative" }}>
        <div className="hero-img" />
        <div
          className="container"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
            height: "10vmin",
          }}
        >
          <h1
            className="text-center"
            style={{
              color: "white",
              textShadow: "2px 2px #000",
              fontSize: "4rem",
              lineHeight: "4rem",
            }}
          >
            My Marketplace
          </h1>
          <h4
            style={{
              color: "#bbb",
              lineHeight: "3rem",
              fontSize: "1.3rem",
              textShadow: "none",
            }}
          >
            Digital collectibles from my company
          </h4>
        </div>
      </div> */}
      {/* <div className="bg_white border-b py-20">
          <div className="container">
            <div className="d-flex justify-content-center">
              <MenuCategories />
            </div>
          </div>
        </div> */}

      {loading && (
        <div className="hero-marketplace">
          <div className="hero-marketplace__loading">
            <div className="hero-marketplace__loading-text">
              Loading Marketplace
            </div>
          </div>
        </div>
      )}
      <iframe
        id="opensea-iframe"
        title="Embedded OpenSea Marketplace"
        src={embeddedUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        style={{
          visibility: loading ? "hidden" : "visible",
        }}
        onLoad={() => {
          setLoading(false);
        }}
      ></iframe>
    </div>
  );
}

export default HeroMarketplace;
