import React from "react";
import { NetlifyForm, Honeypot } from 'react-netlify-forms'
import "./waitlist.scss";

function Waitlist() {
  return (
    <NetlifyForm name='waitList' action='/thanks'
      honeypotName='bot-field'
      data-netlify="true" >
      {({ handleChange, success, error }) => (
        <>
          <Honeypot />
          {success && <p>Thanks for joining!</p>}
          {error && (
            <p>Sorry, we could not reach our servers. Please try again later.</p>
          )}
          <div className="bdhe-landing__waitlist__content">
            <div className="">
              <input type='hidden' name='form-name' value='waitList' />
              <label htmlFor='email'>Email:</label>
              <input className="form-control" type='email' name='email' id='email' placeholder="Input your email" onChange={handleChange} />
              <button type='submit' className="btn">Submit</button>
            </div>
            {/* <div>
          <label htmlFor='message'>Message:</label>
          <textarea
            type='text'
            name='message'
            id='message'
            rows='4'
            onChange={handleChange}
          />
        </div> */}
          </div>
        </>
      )}
    </NetlifyForm>

  );
}

export default Waitlist;
